.privacy {
  display: flex;
  flex-direction: column;
  padding-top: "200px";
}
.PrivacyText {
  padding: 5rem 0 0 0;
}

.PrivacyText h1 {
  text-align: center;
}

.backPP {
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;
}
.mainPharagraphs {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 4rem 0;
}

.PrivacyText p {
  color: #29292a;
  padding: 0.5rem 2rem;
}

.PrivacyText h1 {
  color: #29292a;
  font-size: 2rem;
}
.mainPharagraphs h3{
    font-size: 1.5rem;
}
.mainPharagraphs h3,
.mainPharagraphs p {
  color: #29292a;
  line-height: 25px;
}
.mainPharagraphs span {
  color: #41630f;
  font-weight: 600;
}
.unOdrList {
  display: flex;
  flex-direction: column;
  text-align: flex-start;
  line-height: 2rem;
}

.unOdrList li {
  list-style: disc;
}

@media screen and (max-width: 1300px) {
  .mainPharagraphs {
    width: 85%;
  }

  .privacy p {
    font-size: 1.5rem;
  }

  .mainPharagraphs h3 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .mainPharagraphs {
    width: 85%;
  }

  .PrivacyText p {
    padding: 0.5rem 1rem;
  }

  .privacy p {
    font-size: 1rem;
  }

  .mainPharagraphs {
    padding: 1rem 0;
  }

  .mainPharagraphs h3 {
    font-size: 1.5rem;
  }
  .PrivacyText {
    padding: 0 0.7rem;
  }
}
